<script lang="ts" setup>
    import {ref, computed, watch} from "vue";

    import CountdownTimer from "@songfinch/customer/components/shared/CountdownTimer.vue";

    import dayjs from "dayjs";
    import utc from "dayjs/plugin/utc";
    import timezone from "dayjs/plugin/timezone";
    import isBetween from "dayjs/plugin/isBetween";

    import type {TopBannerDatedItem, TopBannerParams} from "@songfinch/customer/types/top_banner";
    import onPageLoaded from "@songfinch/customer/composables/onPageLoaded";

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(isBetween);

    const emit = defineEmits(["showTopBanner"]);

    const props = defineProps<{
        globalTopBannerPayload?: TopBannerParams;
    }>();

    const activeDatedMessage = ref<TopBannerDatedItem>();
    const timer = ref<ReturnType<typeof setInterval>>();
    const counter = ref<Date | null>(null);
    const duration = ref<number>(1);

    const isComponent = computed(() => {
        return typeof props.globalTopBannerPayload?.component === "object";
    });

    const showTopBanner = computed(() => {
        return !!(isComponent.value || props.globalTopBannerPayload?.text || !!activeDatedMessage.value);
    });

    watch(showTopBanner, val => emit("showTopBanner", val), {immediate: true});

    const bannerColor = computed(() => {
        return (props.globalTopBannerPayload?.color || "@bg-sfc-black");
    });

    const url = computed(() => {
        if (activeDatedMessage.value) {
            return activeDatedMessage.value.url || undefined;
        }
        if (props.globalTopBannerPayload) {
            return props.globalTopBannerPayload.url || undefined;
        }
        return undefined;
    });

    const components = computed(() => {
        return (activeDatedMessage.value?.text || "").split(/(\[.*?])/).map((part) => {
            if (part.startsWith("[") && part.endsWith("]")) {
                const shortcode = part.slice(1, -1);
                if (shortcode === "CountdownTimer") {
                    return {
                        name: CountdownTimer,
                        props: {
                            key: counter.value,
                            dateTime: activeDatedMessage.value?.endDate
                        }
                    };
                }
            }
            return {
                name: "div",
                props: {innerHTML: part}
            };
        });
    });

    const getActiveCountDown = () => {
        if (!props.globalTopBannerPayload?.list) return;
        const now = dayjs.tz(new Date, "America/Chicago");
        activeDatedMessage.value = props.globalTopBannerPayload.list.find((message: TopBannerDatedItem) => {
            const startDate = dayjs.tz(message.startDate, "America/Chicago");
            const endDate = dayjs.tz(message.endDate, "America/Chicago");
            return now.isBetween(startDate, endDate);
        });
        counter.value = new Date();
    };

    watch(
        () => props.globalTopBannerPayload,
        (newPayload) => {
            activeDatedMessage.value = undefined;

            if (newPayload?.list?.length) {
                getActiveCountDown();
                timer.value = setInterval(getActiveCountDown, 60000);
            } else {
                clearInterval(timer.value);
            }
        },
        {immediate: true}
    );

    onPageLoaded(() => {
        duration.value = 500;
    });
</script>

<template>
    <div v-show="showTopBanner" :duration="duration">
        <component
            :is="url ? 'a' : 'div'"
            :href="url"
            class="banner-container l5"
            :class="bannerColor"
            data-top-banner
        >
            <component :is="globalTopBannerPayload.component" v-if="isComponent && globalTopBannerPayload"/>
            <div v-else class="@block @text-sfc-white @container">
                <div
                    v-if="globalTopBannerPayload?.text && !activeDatedMessage?.text"
                    v-html="globalTopBannerPayload?.text"
                />

                <div v-if="activeDatedMessage?.text">
                    <component
                        :is="component.name"
                        v-for="(component, i) in components"
                        :key="i"
                        v-bind="component.props"
                    />
                </div>
            </div>
        </component>
    </div>
</template>

<style lang="scss" scoped>
.banner-container {
    display: flex;
    color: var(--white);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-block: 16px;
    height: 62px;

    @include md {
        height: 47px;
    }

    :deep(p) {
        margin-bottom: 0;
    }
}
</style>
