<template>
    <div class="productDetails @container @text-left">
        <div v-if="currentProduct" class="@grid @grid-cols-1 tablet:@grid-cols-2 @gap-x-8 desktop:@gap-x-16">
            <ProductTitle class="tablet:@col-span-2 tablet:@hidden @mb-6" :product="currentProduct"/>
            <div class="@relative">
                <ProductImageSlider :key="currentProduct.id" class="@mb-6" :product="currentProduct"/>
            </div>
            <div>
                <form class="@max-w-475 @mx-0" @submit.prevent="addToCart">
                    <ProductTitle class="@hidden tablet:@block" :class="{'@mb-6': isAddon}" :product="currentProduct"/>
                    <div>
                        <div v-if="productsOptions" class="@mb-4">
                            <div class="l8 @mb-2" v-text="variantLabel"/>
                            <MkSelect v-model="currentProductSlug" :options="productsOptions"/>
                        </div>
                        <SongSelector v-if="isAddon" :product="currentProduct" @story-changed="error = ''"/>
                        <ArtistGratuityFields v-if="isArtistGratuity" v-model="currentProduct.price" class="@mt-4"/>
                    </div>
                    <div class="@pt-4 text-danger">
                        <SlideUpDown :model-value="error"/>
                    </div>
                    <ProductUrgencyMessage show-icon :urgency-message="currentProduct.extra_info?.urgency_message"/>
                    <button class="mkButton tall @w-full @mt-4 tablet:@mt-4 @mb-10 disabledIfInvalid">
                        ADD TO CART
                    </button>
                    <ProductDescription class="@hidden desktop:@block" :product="currentProduct"/>
                </form>
            </div>
            <ProductDescription class="tablet:@col-span-2 desktop:@hidden" :product="currentProduct"/>
        </div>
    </div>
</template>

<script>
    import ProductImageSlider from "./ProductImageSlider";
    import SongSelector from "./SongSelector";
    import ProductDescription from "./ProductDescription";
    import ArtistGratuityFields from "./ArtistGratuityFields";
    import ProductTitle from "./ProductTitle";
    import MkSelect from "@songfinch/customer/components/shared/MkSelect";
    import SlideUpDown from "@songfinch/shared/components/SlideUpDown";
    import {excludedInstantAddons, loadSingleProduct} from "@songfinch/customer/composables/useProduct";
    import {size, map, forEach, omit} from "lodash-es";
    import infoIcon from "@songfinch/customer/assets/images/info_circle.svg";
    import ProductUrgencyMessage from "@songfinch/customer/components/store/ProductUrgencyMessage.vue";
    import modal from "@songfinch/shared/plugins/modal/index";

    import {coreProductRouter} from "@songfinch/utils/src/config";

    export default {
        name: "ProductDetails",
        components: {ProductUrgencyMessage, ProductTitle, ArtistGratuityFields, ProductDescription, SongSelector, ProductImageSlider, MkSelect, SlideUpDown},
        props: {
            productSlug: {
                type: String,
                default: ""
            },
            productProps: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                currentProductSlug: String(this.productSlug),
                variantsList: {},
                error: "",
                coreProducts: coreProductRouter,
                loadedProduct: null,
                infoIcon
            }; 
        },
        computed: {
            productsOptions() {
                if (size(this.variantsList) > 1) {
                    return map(this.variantsList, v => ({id: v.name, text: (v.extra_info?.variant_name || v.title)}));
                }
                return false;
            },
            currentProduct() {
                let current = this.variantsList[this.currentProductSlug];
                if (current && this.$route.name === "ProductSingle") {
                    document.title = current.title;
                }
                current &&= {...current, ...this.productProps};
                return current || null;
            },
            variantLabel() {
                return this.loadedProduct.extra_info?.variant_label || "Options";
            },
            selectedStory() {
                return this.$store.state.store.selectedStory;
            },
            cart() {
                return this.$store.state.cart.cart;
            },
            isAddon() {
                return this.currentProduct.add_on;
            },
            isArtistGratuity() {
                return this.currentProduct.name === "artist-gratuity";
            }
        },
        async created() {
            try {
                this.loadedProduct = await loadSingleProduct({productSlug: this.productSlug, getWithParent: true});
            } catch (e) {
                await this.$router.push({name: "404"});
                return  false;
            }

            if (!this.loadedProduct) return;
            this.assignProductToList(this.loadedProduct);
            forEach(this.loadedProduct.children, this.assignProductToList);
        },
        methods: {
            async addToCart() {
                this.error = "";
                if (!this.currentProduct) {
                    return false;
                }

                if (this.coreProducts[this.currentProduct.name]) {
                    return this.$router.push(this.coreProducts[this.currentProduct.name].route);
                }

                if (this.currentProduct.bundled_products) {
                    const bundledCoreProduct = Object.keys(this.coreProducts).find(cp => this.currentProduct.bundled_products.find(item => cp === item.name));
                    if (bundledCoreProduct && (this.cart.hasOriginalSong || this.cart.hasInstantSong)) {
                        return this.error = "You can only add one song to your cart at a time. Please remove the current song to add a new one.";
                    }
                    if (bundledCoreProduct === "personalized-song") {
                        this.$store.commit("songBuilder/setSongProduct", this.currentProduct.name);
                        return this.$router.push(this.coreProducts[bundledCoreProduct].route);
                    }
                    if (bundledCoreProduct === "instant-product") {
                        // TODO: ask if we need to proceed the same instant. Because for it we need also collection id
                        return this.error ="Unable to proceed with instant product. Please contact us at support@songfinch.com";
                    }
                }

                if (
                    this.currentProduct?.extra_info?.one_per_story
                    && (this.selectedStory?.products?.find(id => this.currentProduct.id === id)
                        || this.cart.findProductByName(this.currentProduct.name))
                ) {
                    return this.error = `You can have only one ${this.currentProduct.title} per song`;
                }
                if (this.currentProduct.name === "extra-verse" && this.selectedStory && !["shipped"].includes(this.selectedStory.status)) {
                    return this.error = `Extra verses can only be purchased after the song is complete`;
                }
                if (this.selectedStory?.is_instant && excludedInstantAddons.includes(this.currentProduct.name)) {
                    return this.error = `${this.currentProduct.title} cannot be purchased for a Jukebox song.`;
                }

                const productParam = {};
                if (this.selectedStory && this.selectedStory.id !== "in_cart") {
                    productParam.parent_story_id = this.selectedStory.id;
                    productParam.owner_id = this.selectedStory.owner_id;
                }

                await this.$store.dispatch("cart/addProduct", {
                    product: {...this.currentProduct, ...productParam},
                });
                modal.swal.close();
            },
            assignProductToList(p) {
                this.variantsList[p.name] = {...omit(p, "children")};
            }
        }
    };
</script>

<style scoped>
    .productDetails {
        :deep(.productDescription) {
            p {
                margin-bottom: 10px;
            }

            li {
                margin-bottom: 3px;
            }
        }
    }
</style>
