import axios from "axios";
import type {Story, UpdateStorySubmissionType, UppyUploadedFile} from "@songfinch/types";

export const fetchStoryData = async (slug: string): Promise<Story> => {
    const res = await axios.get(`/stories/${slug}`);
    return res.data.data;
};

export const updateStorySubmission = async (slug: string, updateStorySubmission: UpdateStorySubmissionType) => {
    const res = await axios.put(`/stories/${slug}`, updateStorySubmission);
    return res.data;
};

export const getStoryCommentCount = async (storyId: number): Promise<string> => {
    const res = await axios.get(`/stories/${storyId}/comments/count_comments`);
    return res.data.data as string || "0";
};

export const uploadStoryCoverImage = async (slug: string, file: UppyUploadedFile, fileId: string) => {
    const res = await axios.post(`/stories/${slug}/update_cover_image`, {
        photo: JSON.stringify({
            id: fileId,
            storage: "cache",
            metadata: {
                size: file.size,
                filename: file.filename,
                mime_type: file.mime_type,
            }
        })
    });
    
    return res.data;
};

