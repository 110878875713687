<template>
    <img
        v-if="imgSrc"
        class="d-img"
        :src="imgSrc"
        :class="variant"
        :alt="alt"
        :fetchpriority="fetchpriority"
        v-bind="$attrs"
        @error="onImageError"
    >
</template>

<script setup lang="ts">
    import {ref, onMounted} from "vue";

    type DImgPropOptions = {
        format?: "auto" | "jpg" | "png" | "avif" | "webp";
        height?: string;
        width?: string;
    }

    type DImgPropsType = {
        src: string;
        alt: string;
        variant?: "card" | "card-squared" | "fill" | "circle";
        options?: Partial<DImgPropOptions>,
        srcFallback?: string;
        fetchpriority?: string;
    }

    const props = withDefaults(defineProps<DImgPropsType>(), {
        srcFallback: undefined,
        variant: undefined,
        options: () => ({}),
        fetchpriority: "low",
        loading: "lazy",
    });

    const imgSrc = ref<string | undefined>();

    const setImageSrc = (activeImgSrc: string) => {
        const imageOptions = {...{format: "auto"}, ...props.options};

        const queryString = new URLSearchParams(imageOptions).toString();

        imgSrc.value = `${activeImgSrc}?${queryString}`;
    };

    const onImageError = () => {
        if (props.srcFallback) {
            setImageSrc(props.srcFallback);
        }
    };

    onMounted(() => {
        setImageSrc(props.src);
    });
</script>

<style scoped lang="scss">
.d-img {
  max-width: 100%;
}

.card {
  border-radius: var(--radius-20);
}

.card-squared {
  border-radius: var(--radius-20);
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
}

.circle {
  border-radius: 100%;
}

.fill {
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: "center";
}
</style>
