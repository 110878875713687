import app from "@songfinch/customer/app";
import {init, setTag} from "@sentry/vue";
import store from "@songfinch/customer/store";
import Cookies from "js-cookie";
import {merge, mapValues} from "lodash-es";
import useInstantProductBuilder from "@songfinch/customer/composables/useInstantProductBuilder";
import {toValue} from "vue";
import tryReload from "@songfinch/customer/helpers/tryReload";

if (window.appSettings.sentry_dns) {
    const js_hash = window.appSettings.version;
    init({
        app,
        dsn: window.appSettings.sentry_dns,
        //release: window.appSettings.release_version, //???
        normalizeDepth: 10,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: window.appSettings.env === "production" ? 1.0 : 0.25, // ???
        ignoreErrors: [
            /^AbortError:/,
            /^Illegal invocation/, //Some TikTok pixel error
            /^Ignore:/,
            /^Blocked a frame with origin/,
            /^ResizeObserver loop/,
            /^Request aborted/,
            /^Unable to preload CSS/,
            /AutoFillPopupClose/, // caused by Chrome's autofill
            /^Non-Error promise rejection captured/,
            /sentry-browser\.min\.js/ // excluding error everytime when zendesk can't load sentry-browser.min.js cuz of cache
        ],
        denyUrls: [
            /^https?:\/\/(.+\.)?userway\.org/,
            /^chrome-extension:\/\//
        ],
        beforeSend(event, hint) {
            if (!window.appSettings.sentry_dns) { //We can clear this value if we want to stop error tracking
                return null;
            }

            const error = hint.originalException;
            if (
                error?.message?.match(/(Failed to fetch dynamically imported module|Importing a module script failed)/)
                && !hint.captureContext?.extra?.fromReload
            ) {
                if (tryReload(error, "sentry")) return;
            }

            //TODO: add better error handling
            if (hint.captureContext?.extra?.sfErrorIgonre) return;

            //GTM
            const gtm = hint.originalException?.stack?.indexOf("googletagmanager.com/gtm.js?id=") > -1;
            if (gtm) {
                event.tags ||= {};
                event.tags.logger = "gtm";
                event.extra ||= {};
                event.extra.dataLayer = dataLayer.map((i) => {
                    try {
                        return JSON.stringify(i, null, " ");
                    } catch (error) {
                        return "[cannot be serialized]: " + error.message;
                    }
                });
            }

            event.tags.logger ||= "_default_";
            //END GTM


            // UserID Resolution
            // * User (external user id)
            // * Cookie (segment user id)
            // * Cookie (segment anonymous user id)
            // * Cookie (CIO anonymous user id)
            const id = store.state.auth.user?.external_user_id ||
                Cookies.get("ajs_user_id") ||
                Cookies.get("ajs_anonymous_id") ||
                Cookies.get("_cioanonid");

            const extra = {
                cart: store.state.cart,
                song_builder: store.state.songBuilder,
                instant_builder: mapValues(useInstantProductBuilder(), v => (v ? toValue(v.value) : v)),
                js_hash,
            };

            if (error?.redirectsList?.length) {
                extra.redirectsList = error?.redirectsList;
            }

            merge(event, {extra});
            event.user = {id};

            if (event.exception) {
                const frames = event.exception.values?.[0]?.stacktrace?.frames;
                const isOwnError = !frames || frames.some(frame => frame.filename && frame.filename.includes(window.location.hostname));
                event.tags ||= {};
                if (isOwnError) event.tags.sf_error_source = "internal_error";
                else event.tags.sf_error_source = "external_error";
            }

            return event;
        }
    });
    setTag("sf_pack", "de_purchase_first_v1");
    setTag("sf_origin", "frontend");
    setTag("js_hash", js_hash);
}

