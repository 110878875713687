import {useScriptTag} from "@vueuse/core";
import {onBeforeUnmount, onBeforeMount, ref} from "vue";

type UserwayReturn = {
    open: () => void,
    isLoading: boolean
}

export default function useUserway(account: string): UserwayReturn {
    const isLoading = ref(false);

    function hideUserway (event) {
        const instance = event.detail.userWayInstance;
        instance.iconVisibilityOff();
    }
    onBeforeUnmount(() => {
        document.removeEventListener("userway:init_completed", hideUserway);
    });
    document.addEventListener("userway:init_completed", hideUserway);

    onBeforeMount(() => {
        if (!account) console.error("Userway missing account id");
        useScriptTag(
            "https://cdn.userway.org/widget.js",
            () => {},
            {attrs: {"data-account": account}}
        );
    });

    function open() {
        isLoading.value = true;
        UserWay?.widgetOpen();
        setTimeout(() => isLoading.value = false, 3000); // I didn't find the way to check if userway is opened
    }

    return {open, isLoading};
}
