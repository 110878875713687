<script setup lang="ts">
    import DLink from "../atoms/DLink.vue";
    import DText from "../atoms/DText.vue";
    import DImg from "../atoms/DImg.vue";
    import {s3AssetBasePath} from "../assets/config";
    import useUserway from "../composables/useUserway";

    type Props = {
        account: string
    }
    const props = defineProps<Props>();
    const {open, isLoading} = useUserway(props.account);

    const icon = `${s3AssetBasePath}/static/logos/userway.png`;

</script>

<template>
    <DLink
        href="#"
        skip-vue-routing
        class="d-site-footer-navigation-list-item-link userway"
        @click.prevent="open"
    >
        <DImg
            :src="icon"
            width="16"
            height="16"
            alt="userway"
            :class="{'userway-loading': isLoading}"
        />
        <DText variant="p-3" color="muted">
            <slot/>
        </DText>
    </DLink>
</template>

<style lang="scss" scoped>
    .userway {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
            margin-right: var(--size-4);
        }
    }

    @keyframes fadeInOut {
        0% { opacity: 0.3; }
        50% { opacity: 1; }
        100% { opacity: 0.3; }
    }

    .userway-loading {
        animation: fadeInOut 1.5s infinite ease-in-out;
    }
</style>
