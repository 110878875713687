export default {
    extra_verses: {
        one_verse: "One Verse",
        extra_count: "{count} Verses",
        add_more: "Add more extra verses",
        clear: "Clear selection",
        extra_fee: "Extra verse fee: {price}",
        extra_fee_number: "{count} extra verses ({price})"
    }
};
