import {ref, readonly} from "vue";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
import {fetchStoryData} from "@songfinch/data-source/stories";
import {Story} from "@songfinch/types";
import get_error from "@songfinch/shared/helpers/get_error";

import store from "@songfinch/customer/store";

const story = ref<Story>();

export default function useStory() {
    async function showEmailCaptureModal(eventName: string) {
        const EmailCapturePopup = await import("@songfinch/customer/components/story/EmailCapturePopup.vue");

        store.commit("shared/showModal", {
            component: EmailCapturePopup.default,
            props: {
                story: story.value,
                eventName: eventName,
            },
            swalSettings: {
                width: 500,
                showCloseButton: false,
            },
            responsive: {
                1199: {
                    swalSettings: {
                        position: "bottom",
                        width: 347,
                    },
                },
            },
        });
    };

    async function loadCurrentStory(slug: string) {
        story.value = undefined;
        if (!slug) return false;
        try {
            const data = await fetchStoryData(slug);
            story.value = data;
            if (story.value.branded && !["american-greetings", "blue-mountain"].includes(story.value.affiliate as string)) {
                location.href = `/studios/${story.value.affiliate}/stories/${slug}`;
                return false;
            }
            return true;
        } catch (e) {
            $toastMsg(get_error(e));
            return false;
        }
    };

    return {
        story: readonly(story),
        showEmailCaptureModal,
        loadCurrentStory,
    };
}
