import {size, pickBy, isArray, isString, isObject, map, pick, merge} from "lodash-es";
import router from "../router";
import dayjs from "dayjs";
import {useSongBuilderProgress} from "@songfinch/customer/composables/useSongBuilderProgress";
import modal from "@songfinch/shared/plugins/modal/index";
import {getLocalStorage, addLocalStorage, deleteLocalStorage} from "@songfinch/utils/src/storage/localStorage";

export default {
    namespaced: true,
    state: {
        songData: {},
        // song_product_name: "",
        // gifter_email: "",
        // recipient: "",
        // relationship: "",
        // for_someone_else: "", //Yes, No
        // mention_recipient: "", //Yes, No
        // mention_occasion: "", //Yes, No
        // for_occasion: "", //Yes, No
        // occasion: "",
        // deliveryType: "standard",
        // songStreamingProductSelected: false,
        // moods: [],
        // genre: "",
        // tempo: "",
        // gender: "",
        // preferred_artist_switch: null,
        // preferred_artist: "",
        // selected_artists: [],
        // recommended_artists: null,
        // selected_recommended_artist: null
        // require_update_artist: false --- forcing to updateArtist
        // selectedDeliveryMethod: false

        // Story Data
        // pronunciations: [{subject: "", pronounce: ""}],
        // questions: [],
        // must_have_questions: new Array(3).fill(0).map(() => ({answer: "", description: ""})),
        //lastSongBuildPage: "", //Last page before leave song builder
        gtmSongData: {},
        fieldsLimits: {
            question: {maxlength: 10000},
            mustIncTitle: {maxlength: 100},
            mustIncDesc: {maxlength: 200},
        },
    },
    getters: {
        getSongProduct(state) {
            return state.songData.song_product_name || "personalized-song";
        },
        artistData: (state) => {
            if (state.songData?.selected_artists?.[0]) {
                return state.songData.selected_artists[0];
            }
            return state.songData.selected_recommended_artist;
        }
    },
    mutations: {
        setPreferredArtistSwitch(state, val) {
            state.songData.preferred_artist_switch = val;
        },
        loadLocalData(state) {
            const savedData = getLocalStorage("sf_song_data");
            if (savedData) {
                state.songData = {...state.songData, ...savedData};
            }
            if (window.appSettings.sb_data) {
                state.songData = {...state.songData, ...validatedSbData()};
            }
        },
        setSongData(state, val) {
            state.songData = val;
        },
        setSongProduct(state, val) {
            state.songData.song_product_name = val;
            this.commit("songBuilder/updateSongData");
        },
        resetSongData(state) {
            state.songData = {};
            deleteLocalStorage("sf_song_data");
        },
        updateSongData(state, val) {
            if (val) {
                state.songData = merge({}, state.songData, val);
            }
            addLocalStorage("sf_song_data", state.songData);
        },
        setLastSongBuildPage(state, val) {
            state.songData.lastSongBuildPage = val;
        },
        selectArtist(state, data) {
            const artist = data.artist;
            if (!artist) return;
            state.songData.selected_artists ||= [];

            state.songData.preferred_artist_switch = true;

            if (!state.songData.selected_artists?.find(a => a?.id === artist.id)) {
                const artistData = pick(artist, ["id", "artist_name", "photo", "genres", "gender", "fromSinglePage", "available_delivery_days", "artist_power_user", "artist_verified", "exclusivity", "videos"]);
                artistData.genresOptions = map(artistData.genres, (g, i) => ({id: i, text: g}));
                artistData.videos = artistData.videos ? artistData.videos.find(video => video?.video_type === "Artist Intro") || null : null;
                if (size(artistData.genres) === 1) artistData.selected_genre = artistData.genresOptions[0].id;
                state.songData.selected_artists[0] = artistData;
                this.commit("songBuilder/updateSelectedArtistsData");
            }
        },
        removeSelectedArtist(state, index) {
            state.songData.selected_artists?.splice(index, 1);
            this.commit("songBuilder/updateSelectedArtistsData");
        },
        updateSelectedArtistsData(state) {
            state.songData.selected_artists ||= [];
            const selected = state.songData.selected_artists.filter(a => a);
            if (!selected.length) {
                state.songData.preferred_artist = "";
                return;
            }
            state.songData.preferred_artist = selected.map(a => a.artist_name).join(", ");

            const artist = selected[0];
            state.songData.gender = "3";
            state.songData.genre = artist.selected_genre || Object.keys(artist.genres)[0]; // Object.keys can be removed later
        },
        clearSelectedArtists(state) {
            state.songData.selected_artists = undefined;
            state.songData.preferred_artist = "";
        },
        setRecommendedArtists(state, val) {
            state.songData.recommended_artists = val;
            this.commit("songBuilder/updateSongData");
        },
        selectRecommendedArtist(state, val) {
            state.songData.selected_recommended_artist = val;
        },
        addPronunciation(state) {
            state.songData.pronunciations.push({subject: "", pronounce: "", audio: null, saved: false});
        },
        removePronunciation(state, index) {
            state.songData.pronunciations.splice(index, 1);
            addLocalStorage("sf_song_data", state.songData);
        },
        setRequireUpdateArtist(state, val) {
            state.songData.require_update_artist = val;
        }
    },
    actions: {
        async validateSongData({state, commit}, {redirect, toPage} = {}) {
            redirect ??= true;
            const error = "";
            const query = {};
            let showWarning = true;
            let invalidPage = "";

            const {progressStats, sections} = useSongBuilderProgress();

            if (!progressStats.value.valid) {
                sections.find(item => {
                    if (item.isCompleted) return false;
                    invalidPage = item.id;
                    return true;
                });
            }

            if (!invalidPage && state.songData.pronunciations?.length) {
                const startLength = state.songData.pronunciations.length;
                state.songData.pronunciations = state.songData.pronunciations.filter(p => !(p.expire && dayjs().isAfter(p.expire)));
                if (startLength !== state.songData.pronunciations.length) {
                    invalidPage ||= "BsReview";
                    showWarning = false;
                    redirect = toPage !== "BsReview";
                    modal.swal({
                        icon: "warning",
                        html: error || "Your pronunciation audio file has expired. Please add a new one."
                    });
                }
            }

            if (!invalidPage && state.songData.require_update_artist) {
                invalidPage =  "BsSongArtist";
                showWarning = false;
            }

            if (state.songData.selected_artists?.some(a => !a.selected_genre)) {
                invalidPage = "BsSongArtistSelection";
            }

            if (invalidPage) {
                if (showWarning) {
                    modal.swal({
                        icon: "warning",
                        html: error || "Please fill out all required fields before proceeding."
                    });
                }
                commit("setLastSongBuildPage", {name: invalidPage, query});
                if (redirect && router.currentRoute.value.name !== invalidPage) {
                    await router.push({name: invalidPage, query});
                }
                return false;
            }
            return true;
        }
    }
};


function validatedSbData() {
    const data = window.appSettings.sb_data;
    if (!isObject(data)) return;

    const allowedData = {
        gifter_email: v => isString(v) && /(?![+_.-])((?![+_.-][+_.-])[+a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}/.test(v), //email has to pass regex email validation
        recipient: isString,
        relationship: isString,
        mention_recipient: v => isString(v) && ["Yes", "No"].includes(v), //value can be Yes or No
        recipient_pronounce: isString,
        occasion: isString,
        mention_occasion: v => isString(v) && ["Yes", "No"].includes(v), //value can be Yes or No
        moods: v => isArray(v) && v.every(m => isString(m) && parseInt(m)), //array of ids of moods, max 2
        genre: v => isString(v) && parseInt(v), //id
        gender: v => isString(v) && parseInt(v), //id
        tempo: v => isString(v) && parseInt(v) //id
    };
    const invalidData = {};

    const validData = pickBy(data, (val, key) => {
        if (!allowedData[key]?.(val)) {
            invalidData[key] = val;
            return false;
        }
        return true;
    });

    if (size(invalidData)) {
        console.error("Invalid submission data for:", invalidData);
    }

    return validData;
}
